import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Sort from '../../assets/image/sort.svg'
import Sort from '../../assets/image/filter.png'
import Filter from '../../assets/image/menu.png'
// import Sort from '../../assets/image/filter.svg'
// import Filter from '../../assets/image/filterr.svg'
import Dummy from '../../assets/image/user.png'
import Delete from '../../assets/image/delete.png'
import View from '../../assets/image/view.svg'
import Pagination from '../../Components/Pagination/Pagination';
import Spinnerr from "../../Components/Spinner/Spinner";
import { deleteUser, updateStatus, UserList } from '../../ApiComponent/UserManagment';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { debounce } from 'lodash';
import Moment from 'react-moment';
// import blockbefore from '../../assets/image/block-before.svg'
import blockbefore from '../../assets/image/eye.png'
import image from '../../assets/image/logo.png'
import { Button, Form } from 'react-bootstrap';
import { doctorList } from '../../ApiComponent/DoctorManagmentApi';

const DoctorManagment = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([

    ]
    )
    const [filterOption, setFilterOption] = useState("default");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [indexNumber, setIndexNumber] = useState(0)
    const [searchInput, setSearchInput] = useState()
    const [del, setDel] = useState(false)
    const delClose = () => setDel(false);
    const delShow = () => setDel(true);
    const [userId, setUserId] = useState(null);
    const [viewstatus, setViewStatus] = useState("")
    const [block, setBlock] = useState(false);//0 for block/1for unblock
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);
    const [filterday, setfilterday] = useState("");
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: perPage,
        search: "",
        filterday: filterday,
        role: "Doctor"
    });

    const [userid, setUserid] = useState("")
    const [status, setStatus] = useState(0)
    const [selectedButton, setSelectedButton] = useState('doctor'); // Track selected button
    const [total, setTotal] = useState('')


    const UserListApiCall = async (searchQuery) => {
        setLoading(true)
        try {

            const res = await doctorList(searchQuery)
            console.log(res, "==================>>")
            if (res?.data?.code === 200) {
                console.log(res, "UserList Data Reciving======================>")
                setData(res?.data?.data?.data)
                setTotalPages(Math.ceil(res.data.data.total / perPage))
                setTotal(res.data.data.total)
                setLoading(false)
            } else {

                setLoading(false)
                setError(true)
                // localStorage.clear("token")
                // navigate("/")
            }
        } catch (error) {

            console.log(error?.response?.data?.code)
            if (error?.response?.data?.code === 401) {

                toast.error(error?.response?.data?.errorMessage)


            }

        }
    };

    useEffect(() => {
        UserListApiCall(searchQuery)

    }, [searchQuery, selectedButton])



    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchQuery((prevQuery) => ({
                ...prevQuery,
                page: 1,
                search: value,
            }));
        }, 1000),
        []
    );

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        debouncedSearch(value);
    };

    const filter = () => {
        let sendArray = []
        data.forEach((data) => {
            console.log(data, "MyNew Data")
            sendArray.push({
                ["User Name"]: data.full_name,
                ["User mobile"]: data.mobile_number,
                ["User Email"]: data.email,
                ["SpecilistIn"]: "",

            })
        })
        exportToExcel(sendArray, "User Managment")
    }
    const exportToExcel = (data, subject = "exportedData") => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, `${subject}.xlsx`);
    };
    const exportToPDF = () => {
        const doc = new jsPDF();
        // const logo = ""; // Example: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUg...'
        // const logoWidth = 30; // Adjust the width of the logo as needed
        // const logoHeight = 30; // Adjust the height of the logo as needed
        // doc.addImage(logo, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add title "Sports Trade"
        doc.setFontSize(18);
        doc.text("Aesthetic Guru", doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        // Add log with date and time
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}/${currentDate.getMonth() + 1}/${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
        doc.setFontSize(10);
        doc.text(`Generated on: ${formattedDate}`, 10, 20);

        const tableColumn = ["S.No.", "Doctor ID", "Name", "Email Id", "Appointment Done","Specilist In","Status"];
        const tableRows = [];

        data.forEach((user, index) => {
            const userData = [
                index + 1,
                user._id,
                user.full_name,
                user.email,
                user.mobile_numbers === null ? "-":"",
                user.mobile_numbers === null ? "-":"",
                user.status === 0 ? 'Active' : 'Blocked',
                "", // Total Betting Volume placeholder

            ];
            tableRows.push(userData);
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30, // Adjust this value to ensure the table starts below the log text
        });

        doc.save("User_Management.pdf");
    };



    const handleUnBlockUser = async () => {

        console.log(userid, status, "data id=====================>")
        await updateStatus(userid, status)
            .then((response2 => {

                if (response2.data?.code === 200) {
                    console.log(response2, "Response is Here=====================>")
                    setLoading(true)
                    UserListApiCall(searchQuery)
                    blockClose()
                    delClose()
                    if (response2?.data?.data?.status === 0) {
                        toast.success("User Blocked")
                    }
                    else if (response2?.data?.data?.status === 1) {
                        toast.success("User UnBlocked")
                    }
                    else if (response2?.data?.data?.status === 2) {
                        toast.success("User Deleted")
                    }


                }

            }))
    };


    const handlesorting = (criteria) => {

        let sortedData;

        switch (criteria) {
            case 'newest':
                sortedData = [...data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                break;
            case 'oldest':
                sortedData = [...data].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                break;
            default:
                sortedData = data;

        };
        setData(sortedData)
    }




    const handlefilter = (value) => {
        console.log(value);
        setfilterday(value);
        setSearchQuery((prevQuery) => ({
            ...prevQuery,
            filterday: value
        }));
    };



    const handleperpage = async (value) => {
        setLoading(true)
        setSearchQuery({ limit: value })
        setPerPage(value)
        console.log("data_searchQuery====>", searchQuery)
    }
    const paginationHandler = (e, i) => {
        e.preventDefault();
        console.log("iiii", i);
        if (i != page) {
            setPage(i);
            setIndexNumber(perPage * i - perPage);
            UserListApiCall({
                ...searchQuery,
                page: i,
                limit: perPage
            })


        }
    };

    const deleteUsersInfo = async () => {
        await deleteUser(userid)
            .then((response2 => {
                console.log("firstresponse2====>", response2, status)
                if (response2?.data?.code === 200) {
                    console.log(response2, "Response is Here=====================>")
                    setLoading(true)
                    UserListApiCall(searchQuery)
                    delClose()
                    // setActiveStatus(response2?.data?.data?.status)
                    toast.success(response2?.data?.message)
                }
            }))
    };

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                <Modal dialogClassName="modal-custom-sm" centered show={error} onHide={blockClose}>
                    <Modal.Body className='p-0'>
                        <div className="card logout-card common_box">
                            <div className="card-body common_box_body py-4 px-3 text-center">
                                <p className=" fw-bold">Your Token has been expired.</p>
                                <form className="custom_form">
                                    <div className=" text-center">
                                        <Link to='/' className="btn btn-form btn-add items-center mt-4" onClick={() => localStorage.clear()}>Ok</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            ) : (
                <>

                    <div className="dash-first dash-common pt-0">

                        <div className="common-table-wrapper m-0">
                            <div className='mb-3'>
                                <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'doctor' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('doctor');
                                        navigate("/Doctor-Management");
                                    }}
                                >
                                    Doctor list
                                </Button>

                                <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'request' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('request');  // Set the selected button state
                                        navigate("/new-requests");     // Navigate to the new route
                                        // navigate("/Doctor-Managment");     // Navigate to the new route
                                    }}
                                >
                                    New request
                                </Button>

                            </div>
                            <Row className="mb-4">
                                <Col>
                                    <div className='flex gap-3'>
                                        <label class="relative block">
                                            <span class="sr-only">Search</span>
                                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                            </span>
                                            <input class="placeholder:text-black text-black block w-full !border border-[black] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:ring-1 sm:text-base bg-[#fffff]"
                                                placeholder="Search" type="text" name="Search"
                                                value={searchInput}
                                                onChange={handleSearchChange}

                                            />
                                        </label>
                                        <div className="flex gap-3">
                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border min-w-[34px] !border-[black] bg-[white] hover:!border-[black] hover:bg-[white] focus:!bg-[white] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span className="" >  <img src={Sort} alt="sort" className='h-[15px] ' /></span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlefilter(1)}>Last day</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlefilter(2)}>Last 2 days</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlefilter(7)}>Last week</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlefilter(14)}>Last 2 weeks</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlefilter(30)}>Last month</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlefilter("")}>Default</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border min-w-[34px] !border-[black] bg-[white]  hover:!border-[black] hover:bg-[white] focus:!bg-[white] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span onClick={handlesorting}>  <img src={Filter} alt="filter" className='h-[14px] ' /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlesorting('newest')}>Newest</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium' onClick={() => handlesorting('oldest')}>Oldest</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border !border-[black] bg-[white]  hover:!border-[black] hover:bg-[white] focus:!bg-[white] [&_span]:hover:!text-black [&_small]:hover:!text-black  flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span className='text-black transition-all'>Download as </span>
                                                    <small className='text-[10px] text-black ml-2 transition-all'>&#x2BC6;</small>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item onClick={() => { exportToPDF() }} className='text-sm font-medium'>PDF</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { filter() }} className='text-sm font-medium'>XLS</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col md={6}></Col> */}
                            </Row>
                            <div className="table-responsive">
                                {data?.length > 0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Doctor ID</th>
                                                <th>Name</th>
                                                <th>Email Id</th>
                                                {/* <th>Member<br />Since</th> */}
                                                <th>Appointment <br />Done</th>
                                                <th>Specilist <br />In</th>
                                                <th>Status</th>

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {(data?.map(({ _id, User_ID, email, mobile, Total_Appointments, appointmentCount, full_name, status,doctorDetails }, i) => {


                                                return (
                                                    <tr>
                                                        <td>{indexNumber + i + 1}</td>
                                                        <td>{_id || "-"}</td>
                                                        <td className='text-left flex justify-start'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />{full_name}</span></td>

                                                        <td>
                                                            {email || "-"}
                                                        </td>
                                                        {/* <td>----</td>    */}
                                                        <td>{appointmentCount || "-"}</td>
                                                        <td>{doctorDetails?.specialist|| "-"}</td>
                                                        <td className='text-[#FF6600]'>{status == 1 ?   "Blocked":"Active"}</td>

                                                        <td className="action-tab flex justify-center items-center gap-1">
                                                            {/* <button className="w-10 h-10 rounded-full bg-[rgba(255,255,255,.2)] inline-flex items-center justify-center" onClick={() => navigate(`/view-bet-creation/${_id}`)}><img src={blockbefore} alt="" /></button> */}
                                                            <button className="w-[20px] h-[15px] rounded-full bg-[rgba(255,255,255,.2)]  inline-flex items-center justify-center" onClick={() => navigate(`/Doctor-Management/${_id}`)}><img className=' bg-[#e0e0e0] p-1 w-[20px] h-[20px] rounded-full' src={blockbefore} alt="" /></button>

                                                            {/* <button className="w-10 h-10 rounded-full bg-[rgba(255,255,255,.2)] inline-flex items-center justify-center" onClick={() => navigate(`/view-bet-creation${_id}`)}><img src={View} alt="" /></button> */}
                                                            <label class="inline-flex items-center cursor-pointer blockSwitch">
                                                                <input type="checkbox" class="hidden peer"
                                                                    onClick={() => {
                                                                        setUserid(_id)
                                                                        { status == 1 ? setStatus(0) : setStatus(1) }
                                                                        setViewStatus(status)
                                                                        blockShow();
                                                                    }
                                                                    } />
                                                                {/* {status === 1 ? <img src={blockbefore}></img>
                                                            : <img className=' bg-[#e0e0e0] p-1 w-[25px] h-[20px] rounded-full' src={blockbefore} ></img>} */}

                                                            </label>
                                                            <label className="inline-flex items-center cursor-pointer blockSwitch">
                                                            <input type="checkbox"
                                                             checked={status === 0}
                                                                onClick={() => {setUserid(_id); // Set the user ID

                                                                // Check the current status and toggle it
                                                                setViewStatus(status); // Update the view status
                                                                const newStatus = status === 1 ? 0:1;
                                                                setStatus(newStatus); // Update status based on the current value
                                                         

                                                                blockShow(); // Call the blockShow function
                                                            }}
                                                                className="peer hidden" value="" />
                                                            <div className="relative w-11 h-6 bg-[#E4002B] peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#17B517]"></div>
                                                        </label>
                                                            {/* <Form className="custom-switch">
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    
                                                                    onChange={() => {
                                                                        
                                                                />
                                                            </Form> */}
                                                            {/* {status === 2 ? <Link className="w-10 h-10 rounded-full bg-[rgba(255,87,87,0.99)] inline-flex items-center justify-center" onClick={delShow} ><img src={Delete} alt="" /></Link> : */}
                                                            <Link className='w-[20px] h-[19px] rounded-full bg-[rgba(46,44,44,0.99)] inline-flex items-center justify-center' onClick={() => {
                                                                setUserid(_id)
                                                                // setStatus("2")
                                                                // setViewStatus(status)
                                                                delShow();
                                                            }
                                                            } ><img src={Delete} alt="" className='w-[10px] h-[10px] ' /></Link>
                                                            {/* } */}

                                                        </td>
                                                    </tr>
                                                )
                                            }))}
                                        </tbody>
                                    </table>
                                    : <div className=' text-white'> No Data Found</div>}
                            </div>


                            <Row className="mt-4 px-2">

                                <Col md={7}>

                                    <Pagination
                                        data={data}
                                        indexNumber={indexNumber}
                                        totalPage={totalPages}
                                        setPage={setPage}
                                        page={page}
                                        total={total}
                                        paginationHandler={paginationHandler}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Modal dialogClassName="modal-custom-sm" centered show={del} onHide={delClose}>
                        <Modal.Body className='p-0'>
                            <div className="card logout-card common_box">
                                <div className="card-body common_box_body py-4 px-3">

                                    <p className="modalTitle text-center mb-2">Are you sure you want to<br />delete this user?</p>
                                    <form className="custom_form">
                                        <div className="btn-wrapper text-center">
                                            <Link className="btn btn-form btn-cancel" onClick={delClose}>No</Link>
                                            <Link className="btn btn-form btn-add" onClick={deleteUsersInfo}>Yes</Link>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                        <Modal.Body className='p-0'>
                            <div className="card logout-card common_box">
                                <div className="card-body common_box_body py-4 px-3 text-center">
                                    <p className=" fw-bold">Are you sure you want to</p>
                                    <span className=" fw-bold"> {viewstatus == 1 ? "Unblock this user" : " Block this User"}</span>



                                    <form className="custom_form">
                                        <div className="btn-wrapper text-center">
                                            <Link className="btn btn-form btn-cancel" onClick={blockClose}>No</Link>
                                            <Link className="btn btn-form btn-add" onClick={handleUnBlockUser}>Yes</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>)}

        </>
    );
}

export default DoctorManagment