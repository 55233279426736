import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import Edit from '../../assets/image/edit.svg'
import Spinnerr from "../../Components/Spinner/Spinner";
import Term_Conditions from './Term_Conditions';
import Privacy_Policy from './Privacy_Policy';
import Accordion from 'react-bootstrap/Accordion';


import ALM_policy from './ALM_policy';
import White_paper from './White_Paper';
import FAQ from './FAQ';
import { Button } from 'react-bootstrap';
import DoctorTerm_Conditions from './doctorTerm_Conditions';
import DoctorPrivacy_Policy from './doctorPrivacy_Policy';
import Doctorfaq from './DoctorFaq';
const CMSManagement = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
const [selectedButton,setSelectedButton]= useState('doctor')
  const totalPage = 2;
  const [page, setPage] = useState(0);

  const [del, setDel] = useState(false)
  const delClose = () => setDel(false);
  const delShow = () => setDel(true);

  const [block, setBlock] = useState(false);
  const blockClose = () => setBlock(false);
  const blockShow = () => setBlock(true);

  return (
    <>

      {/* <div className='mb-3'>
        <Button
          className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor`}
          onClick={() => {
            // setSelectedButton('doctor');
            // navigate("/Doctor-Management");
          }}
        >
          Terms & conditions
        </Button>
        <Button
          className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor`}
          onClick={() => {
            // setSelectedButton('request'); 
            // navigate("/new-requests");   
          }}
        >
          Privacy Policy
        </Button>
      </div> */}

      {loading ? (
        <Spinnerr />
      ) : error ? (

        <h1 className=" text-4xl font-sfpdBold text-white text-center">SOME  ERROR  OCCURED</h1>
      ) : (<>
       <div className='mb-3'>
                                <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'doctor' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('doctor');
                                        
                                    }}
                                >
                                    Doctor 
                                </Button>

                                <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'User' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('User');  // Set the selected button state
                                             // Navigate to the new route
                                        // navigate("/Doctor-Managment");     // Navigate to the new route
                                    }}
                                >
                                User
                                </Button>

                            </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="TermsNconditions">
          <div className='row align-items-center'>
            <div className="col-md-7 w-full">
              <Nav variant="pills" className="nav-pills-tabs [&_.nav-link]:px-8 [&_.nav-link]:py-2.5 ">
                <Nav.Item><Nav.Link eventKey="TermsNconditions">Terms & conditions</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="PrivacyPolicy">Privacy Policy</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Faq">Faq</Nav.Link></Nav.Item>
              </Nav>
            </div>
          </div>
          <Tab.Content className='mt-4'>
            <Tab.Pane eventKey="TermsNconditions" className='relative'>

           { selectedButton === 'User'?  <Term_Conditions role={selectedButton} />:<DoctorTerm_Conditions/>}
            </Tab.Pane>
            <Tab.Pane eventKey="PrivacyPolicy" className='relative'>
            { selectedButton === 'User'? <Privacy_Policy role={selectedButton} />:<DoctorPrivacy_Policy/>}
            </Tab.Pane>
            <Tab.Pane eventKey="Faq" className='relative'>
            { selectedButton === 'User'? <FAQ/>:<Doctorfaq/>}
            </Tab.Pane>
          </Tab.Content>

        </Tab.Container>
      </>)}
    </>
  );
}

export default CMSManagement;
