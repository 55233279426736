import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sort from '../../assets/image/filterr.svg'
import Filter from '../../assets/image/filter.svg'
import Dummy from '../../assets/image/user-circle.svg'
import Pagination from '../../Components/Pagination/Pagination';
import Spinnerr from "../../Components/Spinner/Spinner";
const BetOrdersManagement = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

  const totalPage = 2;
  const [page, setPage] = useState(0);

  const [del, setDel] = useState(false)
  const delClose = () => setDel(false);
  const delShow = () => setDel(true);

  const [block, setBlock] = useState(false);
  const blockClose = () => setBlock(false);
  const blockShow = () => setBlock(true);

  return (
    <>
     {loading ? (
                <Spinnerr />
            ) : error ? (
               
                <h1 className=" text-4xl font-sfpdBold text-white text-center">SOME  ERROR  OCCURED</h1>
            ) : (<>
      <Tab.Container id="left-tabs-example" defaultActiveKey="Livebets">
        <div className='row align-items-center'>
          <div className="col-md-6">
            <Nav variant="pills" className="nav-pills-tabs [&_.nav-link]:px-8 [&_.nav-link]:py-2.5 [&_.nav-link]:rounded-lg">
              <Nav.Item><Nav.Link eventKey="Livebets">Live Bets</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="settledbets">Settled Bets</Nav.Link></Nav.Item>
            </Nav>
          </div>
        </div>
        <Tab.Content className='mt-4'>
            <Tab.Pane eventKey="Livebets">
                <Tab.Container id="left-tabs-example" defaultActiveKey="UserWise">
                    <div className='row align-items-center'>
                        <div className="col-md-6">
                            <Nav variant="pills" className="nav-pills-tabs [&_.nav-link]:px-8 [&_.nav-link]:py-2.5 [&_.nav-link]:rounded-lg">
                                <Nav.Item><Nav.Link eventKey="UserWise">User wise</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="BetWise">Bet wise</Nav.Link></Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content className='mt-4'>
                        <Tab.Pane eventKey="UserWise">
                            <div className="common-table-wrapper m-0">
                            <Row className="mb-4">
                                <Col md={8}>
                                    <div className='flex gap-3'>
                                        <label class="relative block">
                                            <span class="sr-only">Search</span>
                                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                            </span>
                                            <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                                        </label>
                                        <div className="flex gap-3">
                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span className='text-slate-300 transition-all'>Download as </span>
                                                    <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="table-responsive">
                                <table>
                                    <thead className='[&_th]:!text-lg [&_th]:!leading-[22px]'>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>User ID</th>
                                            <th>User <br/>Name</th>
                                            <th>Sport <br/>Name</th>
                                            <th>Match <br/>Name</th>
                                            <th>Match <br/>Date</th>
                                            <th>Match <br/>Time</th>
                                            <th>Betting <br/>Parameter</th>
                                            <th>Option <br/>Taken</th>
                                            <th>Betting <br/>Date</th>
                                            <th>Betting <br/>Time</th>
                                            <th>ODDS</th>
                                            <th>Amount of Bet</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#1da534]">Live</td>
                                        </tr>
                                        <tr>
                                            <td>02</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#ffc72ed7]">upcoming </td>
                                        </tr>
                                        <tr>
                                            <td>03</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#1da534]">Live</td>
                                        </tr>
                                        <tr>
                                            <td>04</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#ffc72ed7]">upcoming </td>
                                        </tr>
                                        <tr>
                                            <td>05</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#1da534]">Live</td>
                                        </tr>
                                        <tr>
                                            <td>06</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#ffc72ed7]">upcoming </td>
                                        </tr>
                                        <tr>
                                            <td>07</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#1da534]">Live</td>
                                        </tr>
                                        <tr>
                                            <td>08</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#ffc72ed7]">upcoming </td>
                                        </tr>
                                        <tr>
                                            <td>09</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#1da534]">Live</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>01</td>
                                            <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                            <td>lorem ipsum</td>
                                            <td>lorem ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>01/08/2024</td>
                                            <td>11:00AM</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td class="text-[#ffc72ed7]">upcoming </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Row className="mt-4 px-2">
                                <Col md={5}>
                                    <div className="dataTables_info">Showing 1 to 10 of 10 entries</div>
                                </Col>
                                <Col md={7}>
                                    
                                    <Pagination total={totalPage} setPage={setPage} page={page} />
                                </Col>
                            </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="BetWise">
                        <div className="common-table-wrapper m-0">
                            <Row className="mb-4">
                            <Col md={8}>
                                    <div className='flex gap-3'>
                                        <label class="relative block">
                                            <span class="sr-only">Search</span>
                                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                            </span>
                                            <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                                        </label>
                                        <div className="flex gap-3">
                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown className='flex'>
                                                <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                    <span className='text-slate-300 transition-all'>Download as </span>
                                                    <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                    <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                                    <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="table-responsive">
                            <table>
                                <thead className='[&_th]:!text-lg [&_th]:!leading-[22px]'>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Bet ID</th>
                                        <th>Date of <br/>Bet</th>
                                        <th>Time of <br/>Bet</th>
                                        <th>Sport<br/>Name</th>
                                        <th>Match<br/> Name</th>
                                        <th>Date & <br/>Time</th>
                                        <th>Betting<br/> Parameter</th>
                                        <th>No of user that<br/> placed Bet</th>
                                        <th>Total Volume<br/> of placed bets</th>
                                        <th>Max Possible<br/> net liability</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>02</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>03</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>04</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>05</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>06</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>07</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>08</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>09</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>01</td>
                                        <td>18/07/2024</td>
                                        <td>02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>18/07/2024 - 02:00PM</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                        <td>lorem ipsum</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>

                            <Row className="mt-4 px-2">
                                <Col md={5}>
                                    <div className="dataTables_info">Showing 1 to 10 of 10 entries</div>
                                </Col>
                                <Col md={7}>
                                    
                                    <Pagination total={totalPage} setPage={setPage} page={page} />
                                </Col>
                            </Row>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Tab.Pane>
            <Tab.Pane eventKey="settledbets">
                <Tab.Container defaultActiveKey="sb_userWise">
                    <div className='row align-items-center'>
                        <div className="col-md-6">
                            <Nav variant="pills" className="nav-pills-tabs [&_.nav-link]:px-8 [&_.nav-link]:py-2.5 [&_.nav-link]:rounded-lg">
                                <Nav.Item><Nav.Link eventKey="sb_userWise">User wise</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="sb_betWise">Bet wise</Nav.Link></Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content className='mt-4'>
                        <Tab.Pane eventKey="sb_userWise">
                            <div className="common-table-wrapper m-0">
                                <Row className="mb-4">
                                    <Col md={8}>
                                        <div className='flex gap-3'>
                                            <label class="relative block">
                                                <span class="sr-only">Search</span>
                                                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                                    <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                                </span>
                                                <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                                            </label>
                                            <div className="flex gap-3">
                                                <Dropdown className='flex'>
                                                    <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                        <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                        <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <Dropdown className='flex'>
                                                    <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                        <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                        <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <Dropdown className='flex'>
                                                    <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                        <span className='text-slate-300 transition-all'>Download as </span>
                                                        <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                        <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <table>
                                        <thead className='[&_th]:!text-lg [&_th]:!leading-[22px]'>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>User ID</th>
                                                <th>User <br/>Name</th>
                                                <th>Sport <br/>Name</th>
                                                <th>Match <br/>Name</th>
                                                <th>Match <br/>Date</th>
                                                <th>Match <br/>Time</th>
                                                <th>Betting <br/>Parameter</th>
                                                <th>Option <br/>Taken</th>
                                                <th>Betting <br/>Date</th>
                                                <th>Betting <br/>Time</th>
                                                <th>ODDS</th>
                                                <th>Amount of Bet</th>
                                                <th>Total Winning  Amount</th>
                                                <th>Net Revenue</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>02</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>03</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>04</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>05</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>06</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>07</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>08</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>09</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>01</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />Peter Henry</span></td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>01/08/2024</td>
                                                <td>11:00AM</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Row className="mt-4 px-2">
                                    <Col md={5}>
                                        <div className="dataTables_info">Showing 1 to 10 of 10 entries</div>
                                    </Col>
                                    <Col md={7}>
                                        
                                        <Pagination total={totalPage} setPage={setPage} page={page} />
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sb_betWise">
                            <div className="common-table-wrapper m-0">
                                <Row className="mb-4">
                                    <Col md={8}>
                                        <div className='flex gap-3'>
                                            <label class="relative block">
                                                <span class="sr-only">Search</span>
                                                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                                    <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                                </span>
                                                <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                                            </label>
                                            <div className="flex gap-3">
                                                <Dropdown className='flex'>
                                                    <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                        <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                        <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <Dropdown className='flex'>
                                                    <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                        <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                        <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <Dropdown className='flex'>
                                                    <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                        <span className='text-slate-300 transition-all'>Download as </span>
                                                        <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                        <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                                        <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <table>
                                        <thead className='[&_th]:!text-lg [&_th]:!leading-[22px]'>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Bet ID</th>
                                                <th>Date of <br/>Bet</th>
                                                <th>Time of <br/>Bet</th>
                                                <th>Sport<br/>Name</th>
                                                <th>Match<br/> Name</th>
                                                <th>Date & <br/>Time</th>
                                                <th>Betting<br/> Parameter</th>
                                                <th>No of user that<br/> placed Bet</th>
                                                <th>Total Volume<br/> of placed bets</th>
                                                <th>Max Possible<br/> net liability</th>
                                                <th>Net Liability</th>
                                                <th>Net Revenue</th>
                                                <th>status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>02</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>03</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>04</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>05</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>06</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>07</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>08</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                            <tr>
                                                <td>09</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td class="text-[#1da534]">Settled</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>01</td>
                                                <td>18/07/2024</td>
                                                <td>02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>18/07/2024 - 02:00PM</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>lorem ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td class="text-[#ff0000fa]">Expired </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Row className="mt-4 px-2">
                                    <Col md={5}>
                                        <div className="dataTables_info">Showing 1 to 10 of 10 entries</div>
                                    </Col>
                                    <Col md={7}>
                                        
                                        <Pagination total={totalPage} setPage={setPage} page={page} />
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Tab.Pane>
        </Tab.Content>
    </Tab.Container>

      <Modal dialogClassName="modal-custom-sm" centered show={del} onHide={delClose}>
        <Modal.Body className='p-0'>
            <div className="card logout-card common_box">
                <div className="card-body common_box_body py-4 px-3">
                    <p className="modalTitle text-center mb-2">Are you sure you want to<br />delete this user?</p>
                    <form className="custom_form">
                        <div className="btn-wrapper text-center">
                            <Link className="btn btn-form btn-cancel" onClick={delClose}>No</Link>
                            <Link className="btn btn-form btn-add" onClick={delClose}>Yes</Link>
                        </div>
                    </form>
                </div>
            </div>
        </Modal.Body>
      </Modal>
      <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
        <Modal.Body className='p-0'>
            <div className="card logout-card common_box">
                <div className="card-body common_box_body py-4 px-3">
                    <p className="modalTitle text-center mb-2">Are you sure you want to<br />block this user?</p>
                    <form className="custom_form">
                        <div className="btn-wrapper text-center">
                            <Link className="btn btn-form btn-cancel" onClick={blockClose}>No</Link>
                            <Link className="btn btn-form btn-add" onClick={blockClose}>Yes</Link>
                        </div>
                    </form>
                </div>
            </div>
        </Modal.Body>
      </Modal></> )}
    </>
  );
}

export default BetOrdersManagement;
