import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import MdModeEdit from '../../assets/image/edit.svg'
import Spinnerr from '../../Components/Spinner/Spinner';
import { Button, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { AddFAQ, DeleteFAQ, GetFAQ, UpdateFAQ } from '../../ApiComponent/CMSApi';
import Delete from '../../assets/image/delete.png'
function Doctorfaq() {
    const [faqData, setFaqData] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [newFaq, setNewFaq] = useState({ title: "", description: "" });
    const [editFaq, setEditFaq] = useState({ id: "", title: "", description: "" });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedFaqId, setSelectedFaqId] = useState("");
    // const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
//     const [deleteId, setDeleteId] = useState(null);
//    const [question,setQuestion]=useState()
//    const [answer,setAnswer]=useState()
    const body = {
        title: newFaq.question,
        description: newFaq.answer
    };
    const getFaq=()=>{
        GetFAQ('Doctor')      
        .then((res => {
            console.log("res============",res)
            if (res?.status === 200) {
                
                const sortedData = res?.data?.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setFaqData(sortedData);
                setFaqData(res?.data?.data)
                setLoading(false);
            }
            else if (res?.status!== 200) {

                setError(true);
                setLoading(false);
            }
        }))
    }
    useEffect(() => {
        console.log(newFaq,"data")
     getFaq()
    }, [])

    const handleEdit = (id, title, description) => {
        setSelectedFaqId(id);
        setShowEditForm(true);
        setShowAddForm(false);
        setEditFaq({ id, title, description });
    };

    const handleSaveEdit = async (updatedEditFaq) => {
        if (updatedEditFaq?.description.trim().length === 0 || updatedEditFaq?.title.trim().length === 0) {
            toast.error("Question and Answer fields can't be blank.");
            return;
        }

        try {
            const res = await UpdateFAQ(updatedEditFaq);
            if (res?.data?.code === 200) {
                setShowEditForm(false);
                setLoading(true);
                getFaq();
                toast.success("FAQ updated successfully.");
            } else {
                toast.error("Failed to update FAQ.");
            }
        } catch (error) {
            toast.error(error?.response?.data?.errorMessage || "An error occurred while updating FAQ.");
            console.error("API update error:", error);
        }
    };

    const handleAddNew = async (body) => {
        if (newFaq.description.trim().length === 0 || newFaq.title.trim().length === 0) {
            toast.error("Question and Answer fields can't be blank.");
            return;
        }

        

        try {
            
            const body = {
                title: newFaq.title,
                description: newFaq.description,
                role_wise:'Doctor'
            };
            console.log(body,".........................")
            const req = await AddFAQ(body);
            if (req?.data?.code === 200) {
                setShowAddForm(false);
                setLoading(true);
                getFaq();
                toast.success("FAQ added successfully.");
                setNewFaq({ title: "", description: "" });
            } else {
                toast.error("Failed to add FAQ.");
            }
        } catch (error) {
            toast.error(error?.response?.data?.errorMessage || "An error occurred while adding FAQ.");
            console.error("API add error:", error);
        }
    };
    
    const handleDelete=async(id)=>{
        try {
            const res = await DeleteFAQ(id);
            if (res?.data?.code === 200) {
                setShowEditForm(false);
                setLoading(true);
                getFaq();
                toast.success("FAQ deleted successfully.");
            } else {
                toast.error("Failed to delete FAQ.");
            }
        } catch (error) {
            toast.error(error?.response?.data?.errorMessage || "An error occurred while updating FAQ.");
            console.error("API update error:", error);
        }
    }



  return (
    <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "Some error occurred."
            ) : (
                <>
                    <div className="dash-first dash-common mb-4">
                        <div className="card-head p-0 text-end">
                            <button
                                className="text-white bg-orange-500 hover:text-sky-800 outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
                                onClick={() => {
                                    setShowAddForm(true)
                                    setShowEditForm(false)}}
                            >
                                + Add New
                            </button>
                        </div>
                        {showAddForm && (
                            <div className="dash-first dash-common box-border-3 mb-4 mt-2" id="add-faq">

                                <div className="form-group mb-3">
                                    <label className="mb-2">Question</label>
                                    <textarea
                                            className="w-100 rounded-50 p-3"
                                            
                                            onChange={(text)=>setNewFaq({...newFaq,title:text.target.value})}

                                            />
                                </div>
                                <div className="form-group">
                                    <label className="mb-2">Answer</label>
                                   
                                    <textarea
                                            className="w-100 rounded-50 p-3"
                                          
                                            rows={10}
                                            onChange={(text)=>setNewFaq({...newFaq,description:text.target.value})}

                                            />
                                </div>
                                <div className="text-end mt-3 flex justify-end gap-3">
                                    <button
                                        className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={() => {setShowAddForm(false)
                                            setNewFaq({ title: "", description: "" });
                                        }
                                    
                                    }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={handleAddNew}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        )}
                      
                        {faqData.map((faq) => ( 
                                  
                            <div key={faq._id } className="card-body faq-list box-border-3 py-3 mt-4" style={{justifyContent:"space-between"}}>
                              
                                {selectedFaqId === faq._id  && showEditForm ? (
                                    <div className="dash-first dash-common box-border-3 mb-4 w-100" id="edit-faq">
                                        <div className="form-group mb-3">
                                            <label className="mb-2">Question</label>
                                           
                                            <textarea className="w-100 rounded-50 p-3"
                                            value={editFaq.title}
                                            onChange={(text)=>setEditFaq({...editFaq,title:text.target.value})}/>
                                           
                                           
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2">Answer</label>
                                            <textarea className="w-100 rounded-50 p-4"
                                            rows={10}
                                            value={editFaq.description}
                                            onChange={(text)=>setEditFaq({...editFaq,description:text.target.value})}/>
                                           
                                            
                                        </div>
                                        <div className="text-end mt-3 flex justify-end gap-3">
                                            <button
                                                className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                                onClick={() => setShowEditForm(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                                onClick={() => {
                                                    const updatedEditFaq = { ...editFaq, id: faq._id, role_wise:'Doctor' };
                                                    setEditFaq(updatedEditFaq);
                                                    handleSaveEdit(updatedEditFaq);
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                       <div >
                                        <label style={{fontWeight:"bold"}}>Question</label>
                                       <p className="mb-0 line-clamp" dangerouslySetInnerHTML={{ __html:faq.title  }}/>
                                       <label style={{fontWeight:"bold"}}>Answer</label>
                                        <p className="mb-0 line-clamp"style={{height:"max-content"}} dangerouslySetInnerHTML={{ __html:faq.description  }}/>
                                       </div>

                                        <div className="d-flex">
                                            <button className="edit-icon me-2" onClick={() => handleEdit(faq._id,faq.title,faq.description)}>
                                                <img src={MdModeEdit} className=' '></img>

                                            </button>
                                            <button className="edit-icon me-2" onClick={() => handleDelete(faq._id)}>
                                                <img src={Delete} className=' '></img>
                                                
                                            </button>
                                           
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
           
        </>
    );
};

export default Doctorfaq