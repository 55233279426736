import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Sort from '../../assets/image/sort.svg'
import Sort from '../../assets/image/filter.png'
import Filter from '../../assets/image/menu.png'
// import Sort from '../../assets/image/filter.svg'
// import Filter from '../../assets/image/filterr.svg'
import Dummy from '../../assets/image/user-circle.svg'
import Delete from '../../assets/image/delete.png'
import View from '../../assets/image/view.svg'
import Pagination from '../../Components/Pagination/Pagination';
import Spinnerr from "../../Components/Spinner/Spinner";
import { deleteUser, updateStatus, UserList } from '../../ApiComponent/UserManagment';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { debounce } from 'lodash';
import Moment from 'react-moment';
import { useLocation } from "react-router-dom";
// import blockbefore from '../../assets/image/block-before.svg'
import blockbefore from '../../assets/image/eye.png'
import image from '../../assets/image/logo.png'
import { Button, Form } from 'react-bootstrap';
import { doctorView } from '../../ApiComponent/DoctorManagmentApi';
import profileImg from '../../assets/image/user.png'
import { ImgUrl } from '../../config/Base';


const ViewBetCreation = () => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    console.log(id, "========idididi=========")
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([
       
    ]
    )
    const [filterOption, setFilterOption] = useState("default");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [indexNumber, setIndexNumber] = useState(0)
    const [searchInput, setSearchInput] = useState()
    const [del, setDel] = useState(false)
    const delClose = () => setDel(false);
    const delShow = () => setDel(true);
    const [userId, setUserId] = useState(null);
    const [viewstatus, setViewStatus] = useState("")
    const [block, setBlock] = useState(false);//0 for block/1for unblock
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);
    const [filterday, setfilterday] = useState("");
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: perPage,
        search: "",
        filterday: filterday,
        role: "Doctor"
    });

    const [userid, setUserid] = useState("")
    const [status, setStatus] = useState("")
    const [profile, setProfile]= useState('')
    const [selectedButton, setSelectedButton] = useState(null); // Track selected button
    const [doctorDetails, setDoctorDetails] = useState(null);

    const UserListApiCall = async (searchQuery) => {
        // setLoading(true)
        // try {

        //     const res = await UserList(searchQuery)
        //     console.log(res, "==================>>")
        //     if (res?.data?.code === 200) {
        //         console.log(res, "UserList Data Reciving======================>")
        //         setData(res?.data?.data)
        //         setTotalPages(Math.ceil(res?.data?.data?.totalRecords / perPage))
        //         setLoading(false)
        //     } else {
        //     }
        // } catch (error) {

        //     console.log(error?.response?.data?.code)
        //     if (error?.response?.data?.code === 401) {

        //         toast.error(error?.response?.data?.errorMessage)


        //     }

        // }
    };

    const userviewfunction = () => {
        try {
            doctorView(id)
                .then(res => {
                    if (res?.data?.code === 200) {
                        setLoading(false)
                        let img= ImgUrl+res?.data?.data?.profile
                        setProfile(img)
                        setDoctorDetails(res.data.data);
                    }
                })

        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    useEffect(() => {
        userviewfunction()
    }, [])

    useEffect(() => {
        UserListApiCall(searchQuery)

    }, [searchQuery])



    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchQuery((prevQuery) => ({
                ...prevQuery,
                page: 1,
                search: value,
            }));
        }, 1000),
        []
    );

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        debouncedSearch(value);
    };

    const filter = () => {
        let sendArray = []
        data.forEach((data) => {
            console.log(data, "MyNew Data")
            sendArray.push({
                ["User Id"]: data._id,
                ["User Name"]: data.full_name,
                ["User mobile"]: data.mobile,


                ["Registration Date"]: data.createdAt,
                ["Total Betting Volume"]: "",
                ["No of bets (User)"]: "",

                ["Total Winning Amount"]: "",
                ["Net Revenue (User)"]: "",

            })
        })
        exportToExcel(sendArray, "User Managment")
    }
    const exportToExcel = (data, subject = "exportedData") => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, `${subject}.xlsx`);
    };
    const exportToPDF = () => {
        const doc = new jsPDF();
        // const logo = ""; // Example: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUg...'
        // const logoWidth = 30; // Adjust the width of the logo as needed
        // const logoHeight = 30; // Adjust the height of the logo as needed
        // doc.addImage(logo, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add title "Sports Trade"
        doc.setFontSize(18);
        doc.text("Sports Trade", doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        // Add log with date and time
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}/${currentDate.getMonth() + 1}/${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
        doc.setFontSize(10);
        doc.text(`Generated on: ${formattedDate}`, 10, 20);

        const tableColumn = ["S.No.", "User ID", "Name", "mobile", "Member Since", "Total Betting Volume", "No of bets (User)", "Total Winning Amount", "Net Revenue (User)"];
        const tableRows = [];

        data.forEach((user, index) => {
            const userData = [
                index + 1,
                user._id,
                user.full_name,
                user.mobile,
                user.createdAt,
                "", // Total Betting Volume placeholder
                "", // No of bets (User) placeholder
                "", // Total Winning Amount placeholder
                "" // Net Revenue (User) placeholder
            ];
            tableRows.push(userData);
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30, // Adjust this value to ensure the table starts below the log text
        });

        doc.save("User_Management.pdf");
    };



    const handleUnBlockUser = async () => {

        console.log(userid, status, "data id=====================>")
        await updateStatus(userid, status)
            .then((response2 => {

                if (response2.data?.code === 200) {
                    console.log(response2, "Response is Here=====================>")
                    setLoading(true)
                    UserListApiCall(searchQuery)
                    blockClose()
                    delClose()
                    if (response2?.data?.data?.status === 0) {
                        toast.success("User Blocked")
                    }
                    else if (response2?.data?.data?.status === 1) {
                        toast.success("User UnBlocked")
                    }
                    else if (response2?.data?.data?.status === 2) {
                        toast.success("User Deleted")
                    }


                }

            }))
    };




    const handleperpage = async (value) => {
        setLoading(true)
        setSearchQuery({ limit: value })
        setPerPage(value)
        console.log("data_searchQuery====>", searchQuery)
    }
    const paginationHandler = (e, i) => {
        e.preventDefault();
        console.log("iiii", i);
        if (i != page) {
            setPage(i);
            setIndexNumber(perPage * i - perPage);
            UserListApiCall({
                ...searchQuery,
                page: i,
                limit: perPage
            })


        }
    };

    const deleteUsersInfo = async () => {
        await deleteUser(userid)
            .then((response2 => {
                console.log("firstresponse2====>", response2, status)
                if (response2?.data?.code === 200) {
                    console.log(response2, "Response is Here=====================>")
                    setLoading(true)
                    UserListApiCall(searchQuery)
                    delClose()
                    // setActiveStatus(response2?.data?.data?.status)
                    toast.success(response2?.data?.message)
                }
            }))
    };

    const InfoRow = ({ label, value }) => {
        return (
            <div style={styles.row}>
                <div style={styles.label}>{label}</div>
                <div style={styles.value}>{value}</div>
            </div>
        );
    };


    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
      };

    return (
        <>

            <div className="dash-first dash-common pt-0">

                <div className="common-table-wrapper m-0 justify-center">
                    <div className='flex-row flex '>
                        <Col xl={2}>
                            <Link to={"/Doctor-Management"} className="border-[1px] px-4 py-1 inline-block text-[black] hover:text-[#black]  bg-[#fff] hover:bg-[white] border-[black] hover:border-[black] transition-all">Back</Link>
                        </Col>

                        <div className='flex items-center  ml-[330px] -mt-4 flex-col mb-4'>
                            <img src={profile?profile:profileImg} className='h-[50px] w-[50px]'></img>
                            <h1>{doctorDetails?.full_name}</h1>
                        </div>
                    </div>


                    <div>
                        <div style={styles.container}>
                            <h3 className='text-[#666] text-sm'>Doctor's info</h3>
                            <div style={styles.infoBox}>
                                <p>{console.log("doctorDetails.email", doctorDetails)}</p>
                                {doctorDetails && (
                                    <>
                                        <InfoRow label="Work email" value={doctorDetails.email} />
                                        <InfoRow label="Full Name" value={doctorDetails.full_name} />
                                        <InfoRow label="Specialist in" value={doctorDetails.specialist} />
                                        <InfoRow label="Gender" value={doctorDetails.gender} />
                                        <InfoRow label="Medical ID number" value={doctorDetails.medical_id_number} />
                                        <InfoRow label="Residence country" value={doctorDetails.country} />
                                        <InfoRow label="Practice city" value={doctorDetails.city} />
                                        <InfoRow label="State" value={doctorDetails.state} />
                                        <InfoRow label="Awards" value={doctorDetails.award} />
                                        {/* If there's a National ID/passport file, you can render it as a downloadable link */}
                                        <InfoRow
                                            label="National ID/passport"
                                            value={<a href={ImgUrl+doctorDetails?.nation_id} className='text-blue-400' target="_blank" rel="noopener noreferrer">{truncateText(doctorDetails?.nation_id,20)}</a>}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        {data?.length > 0 ?
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Booking ID</th>
                                        <th>Date</th>
                                        <th>User</th>
                                        <th>Surgery <br />chosen</th>
                                        {/* <th>MemberSince</th> */}
                                        <th>Scanned <br />face</th>
                                        <th>Provious patient <br />record</th>
                                        <th>Status</th>

                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>


                                    {(data?.map(({ _id, User_ID, email, mobile, Total_Appointments, appointmentCount, full_name, status }, i) => {


                                        return (
                                            <tr>
                                                <td>{indexNumber + i + 1}</td>
                                                <td>{User_ID || "-"}</td>
                                                <td className='text-center'><span className="inline-flex gap-2 items-center"><img src={Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />{full_name}</span></td>
                                                <td>{mobile || "-"}</td>
                                                <td>
                                                    {email || "-"}
                                                </td>
                                                {/* <td>----</td>    */}
                                                <td>{appointmentCount || "-"}</td>
                                                <td>{Total_Appointments || "-"}</td>
                                                <td className='text-[#FF6600]'>{status == 1 ? "Active" : "Blocked"}</td>


                                            </tr>
                                        )
                                    }))}
                                </tbody>
                            </table>
                            : <div className=' text-white'> No Data Found</div>}
                    </div>


                    <Row className="mt-4 px-2">

                        <Col md={7}>

                            <Pagination
                                data={data}
                                indexNumber={indexNumber}
                                totalPage={totalPages}
                                setPage={setPage}
                                page={page}
                                paginationHandler={paginationHandler}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal dialogClassName="modal-custom-sm" centered show={del} onHide={delClose}>
                <Modal.Body className='p-0'>
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">

                            <p className="modalTitle text-center mb-2">Are you sure you want to<br />delete this user?</p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={delClose}>No</Link>
                                    <Link className="btn btn-form btn-add" onClick={deleteUsersInfo}>Yes</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className='p-0'>
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3 text-center">
                            <p className=" fw-bold">Are you sure you want to</p>
                            <span className=" fw-bold"> {viewstatus == 0 ? "Unblock this user" : " Block this User"}</span>



                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>No</Link>
                                    <Link className="btn btn-form btn-add" onClick={handleUnBlockUser}>Yes</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* </>)} */}

        </>
    );
}

const styles = {
    container: {
        width: '400px',
        // padding: '20px',
        borderRadius: '10px',
        // backgroundColor: '#f5f5f5',
        // fontFamily: 'Arial, sans-serif',
        // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    infoBox: {
        backgroundColor: '#e0e0e0',
        padding: '15px',
        borderRadius: '8px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 0',
        // borderBottom: '1px solid #ccc',
    },
    label: {
        color: '#666',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    value: {
        color: '#333',
        fontSize: '14px',
    },



};

export default ViewBetCreation