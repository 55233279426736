import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sort from "../../assets/image/filterr.svg";
import Filter from "../../assets/image/sort.svg";
import Edit from "../../assets/image/edit.svg";
import Pagination from "../../Components/Pagination/Pagination";
import Spinnerr from "../../Components/Spinner/Spinner";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  ListAllCredits,
  UpdateCredit,
} from "../../ApiComponent/CreditsManagement";
import { toast } from "react-toastify";
const CreditsManagement = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [btstate,setbtstate]=useState(false)
  const totalPage = 2;
  const [page, setPage] = useState(0);
  const navigate=useNavigate()
  const [allCredits, setAllCredits] = useState();

  const [del, setDel] = useState({
    value: false,
    type: "",
    reward: "",
    id: "",
  });
  const delClose = () => setDel({ value: false, type: "", reward: "", id: "" });
  const delShow = (data) => {
    setDel({
      value: true,
      type: data?.type,
      reward: data?.reward,
      id: data?.id,
    });
  };

  const [block, setBlock] = useState(false);
  const blockClose = () => setBlock(false);
  const blockShow = () => setBlock(true);

  const handleCredList = async (e) => {
    try {
      const res = await ListAllCredits();
console.log(res?.data?.data,"================DAta credit")
      if (res?.status === 200) {
        setAllCredits(res?.data?.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error?.response?.data?.code)
      if(error?.response?.data?.code===401){
       localStorage.clear("token")
       toast.error(error?.response?.data?.errorMessage)
       navigate("/")
      
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

  // Check if the reward is zero
  if (del?.reward === "" || del?.reward === "0" || Number(del?.reward) === 0) {
    toast.error("Reward field can't be zero.");
    return; // Exit the function early
  }
 
    setbtstate(true)
    // if (del.reward===0) {
    //   toast.error("Please enter reward cash");
    //   return;
    // }
    const credDetails = {
      reward_cash: del?.reward,
      credit_type: del?.type,
      credit_id: del?.id,

    };
    
    try {
      const res = await UpdateCredit(credDetails);

      if (res?.status === 200) {
        handleCredList();
        delClose();
        toast.success("Reward updated successfully!");
        setbtstate(false)
      } else {
        toast.error("Something went wrong");
        setbtstate(false)
      }
    } catch (error) {
      toast.error("Reward Field Can't Be Blank");
      setbtstate(false)
    }
  };

  useEffect(() => {
    handleCredList();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    
    setDel((prevState) => ({ ...prevState, reward: e.target.value }));
  };

  const filter = () => {
    let sendArray = []
    allCredits.forEach((data,index) => {
        console.log(data, "MyNew Data")
        sendArray.push({   
          ["S.No"]:index+1,        
            ["Type"]: data.type,
            ["Bets Needed"]: "2",
            ["Reward Cash"]: data.reward_cash,
        })
    })
    exportToExcel(sendArray, "Credits Managment")
}
const exportToExcel = (data, subject = "exportedData") => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, `${subject}.xlsx`);
};
const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Sports Trade", doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

    const tableColumn = ["S.No.", "Type", "Bets Needed", "Reward Cash"];
    const tableRows = [];

    allCredits.forEach((user, index) => {
        const userData = [
            index + 1,
            user.type,
            "2",
            user.reward_cash,
            
            "", // Total Betting Volume placeholder
            "", // No of bets (User) placeholder
            "", // Total Winning Amount placeholder
            "" // Net Revenue (User) placeholder
        ];
        tableRows.push(userData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("User_Management.pdf");
};

  return (
    <>
      {loading ? (
        <Spinnerr />
      ) : error ? (
        <h1 className=" text-4xl font-sfpdBold text-white text-center">
          SOME ERROR OCCURED
        </h1>
      ) : (
        <>
         <div className="mb-4">
                                      
                                        <Dropdown className='flex'>
                                            <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                                <span className='text-slate-300 transition-all'>Download as </span>
                                                <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                                <Dropdown.Item onClick={() => { exportToPDF() }} className='text-sm font-medium'>PDF</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { filter() }} className='text-sm font-medium'>XLS</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                               
          <div className="dash-first dash-common pt-0">
            <div className="common-table-wrapper m-0">
           
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Type</th>
                      <th>Bets needed</th>

                      <th>
                        Reward <br />
                        Cash
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCredits?.length > 0 &&
                      allCredits?.map((data, index) => {
                        return (
                          <>
                            <tr key={data?._id}>
                              <td>{index + 1}</td>
                              <td>{data?.type}</td>
                              <td>2</td>
                              <td>{`$ ${data?.reward_cash}`}</td>
                              <td className="action-tab flex justify-center items-center gap-2">
                                <Link
                                  className="w-10 h-10 rounded-full bg-[rgba(255,255,255,.2)] inline-flex items-center justify-center"
                                  onClick={() =>
                                    delShow({
                                      type: data?.type,
                                      reward: data?.reward_cash,
                                      id: data?._id,
                                    })
                                  }
                                >
                                  <img src={Edit} alt="" className="h-4" />
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              {/* <Row className="mt-4 px-2">
                <Col md={5}>
                  <div className="dataTables_info">
                    Showing 1 to 10 of 10 entries
                  </div>
                </Col>
                <Col md={7}>
                  <Pagination total={totalPage} setPage={setPage} page={page} />
                </Col>
              </Row> */}
            </div>
          </div>
          <Modal
            dialogClassName="max-w-[390px]"
            centered
            show={del?.value}
            onHide={delClose}
          >
            <Modal.Body className="p-0">
              <div className="card common_box !rounded-sm px-3">
                <div className="card-body common_box_body py-4 px-3">
                  <h2 className="text-3xl text-center">Edit credit</h2>
                  <p className="text-center mb-2 mt-1 text-lg text-white">
                    {del?.type}
                  </p>
                  <form className="custom_form">
                    <div class="relative my-4">
                      <div class="absolute inset-y-0 end-4 flex items-center ps-3.5 pointer-events-none font-semibold text-[#000000] opacity-40 text-lg">
                        $
                      </div>
                      <input
                        type="number"
                        id=""
                        value={del?.reward}
                        class="bg-gray-10 border border-gray-300 rounded-md font-normal block w-full pe-10 p-2.5 py-2.5 text-lg focus:outline-none text-black"
                        placeholder="Enter cash"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="btn-wrapper text-center gap-3">
                      <Link
                        className="btn btn-form btn-cancel !w-full"
                        onClick={delClose}
                      >
                        Cancel
                      </Link>
                      <button
                        className="btn btn-form btn-add !w-full"
                        disabled={btstate}
                        onClick={handleUpdate}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>{" "}
        </>
      )}
    </>
  );
};

export default CreditsManagement;
